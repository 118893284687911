import React from 'react'
import { useTheme } from 'styled-components'

const NotCollectingIcon = () => {
  const theme = useTheme()
  return (
    <svg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>Usage/No data</title>
      <g id='Usage/No-data' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='core'>
          <g id='Group-2'>
            <circle id='Oval-2' fill={theme.palette.icon.disabled} cx='8' cy='8' r='8' />
            <rect id='Rectangle-3-Copy' fill={theme.palette.background.primary} fillRule='nonzero' x='3' y='7' width='10' height='2' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default NotCollectingIcon
