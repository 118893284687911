import UserDetails from '@components/userDetails'
import { User } from '@components/userDetails/types'
import { taskNameColumn, taskTypeColumn, dateTimeColumn, serialNumberColumn } from '../shared/columns'

export const completedTasksColumns = [
  serialNumberColumn,
  taskNameColumn,
  {
    Header: 'Completed by',
    accessor: 'completedBy',
    Cell: ({ row: { completedBy } }: { row: { completedBy?: User }}) => {
      if (!completedBy) {
        return '-'
      }

      return <UserDetails idUser={completedBy.id} {...completedBy} />
    },
    minWidth: 170
  },
  taskTypeColumn,
  {
    Header: 'Completed at (UTC)',
    accessor: 'completedAt',
    ...dateTimeColumn,
    maxWidth: 240
  }
]
