import React, { ReactElement, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TabsPage from '@pages/tabs'
import { getIdOrg } from '@selectors/org'
import OpenTasksTab from './components/openTasksTab'
import CompletedTasksTab from './components/completedTasksTab'
import CanceledTasksTab from './components/canceledTasksTab'
import { getOpenTasksAmount as getOpenTasksAmountSelector } from '@store/selectors/tasks'
import { getOpenTasksAmount as getOpenTasksAmountAction } from '@store/actions/tasks'

const TasksPage = (): ReactElement => {
  const onTasksSettingsButtonClick = () => {
    console.error('Not implemented') // TODO-OM - implement
  }

  const idOrg = useSelector(getIdOrg)
  const dispatch = useDispatch()
  const openTasksAmount = useSelector(getOpenTasksAmountSelector)

  useEffect(() => {
    dispatch(getOpenTasksAmountAction({ idOrg }))
  }, [dispatch, idOrg])

  const tabsName = [
    'open',
    'completed',
    'canceled'
  ]

  const tabsHeader = [
    { header: 'Open', counter: openTasksAmount },
    { header: 'Completed' },
    { header: 'Canceled' }
  ]

  const tabsContent = [
    <OpenTasksTab />,
    <CompletedTasksTab />,
    <CanceledTasksTab />
  ]

  return (
    <TabsPage
      pageHeader='Tasks'
      pageTitle='Tasks Management Hub'
      pageName='Tasks'
      path={`/team/${idOrg}/tasks`}
      tabsName={tabsName}
      tabsHeader={tabsHeader}
      tabsContent={tabsContent}
      menuButtonText={'Tasks Settings'}
      menuButtonOnClick={onTasksSettingsButtonClick}
    />
  )
}

export default TasksPage
