import styled from 'styled-components'

export const TooltipContent = styled.div`
  text-align: center;
  max-width: 320px;
`

export const DateText = styled.span`
  font-weight: 600;
`

export const BarContainer = styled.span`
  display: inline-block;
`

interface BarProps {
  margin: number
  isActive: boolean
  color: string
  width: number
  height: number
}

export const Bar = styled.div<BarProps>`
  display: inline-block;
  margin-right: ${({ margin }) => margin}px;
  background: ${({ isActive, theme }) => isActive ? theme.palette.icon.emphasis : theme.palette.icon.disabled};
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`
