import { DeleteImportedUsersPopupState, UiAction, UiState } from '@reducers/ui/types'
import { Widget, WIDGET_TYPES } from '@reducers/dashboards/types'
import { getTableWidgetTableKey, getWidgetTableKey } from '@components/dashboard/widgets/shared'
import { createSortObjectArrayFromSortStringArray } from '@shared/utils'
import initialDashboardsBySystemKey from '@actions/initialDashboards'
import {
  TABLES,
  APP_USERS_CUSTOM_SELECT_OPTIONS_FOR_NON_CONNECTED_APPS,
  EXPENSES_TABLE_CUSTOM_SELECT_OPTIONS,
  EXPENSES_TABLE_SECONDARY_CUSTOM_SELECT_OPTIONS,
  EXPENSES_RANGE_OPTIONS,
  LICENSES_CUSTOM_SELECT_OPTIONS,
  INTEGRATIONS_TABLE_CUSTOM_SELECT_OPTIONS,
  INACTIVE_USERS_REPORT_TABLE_CUSTOM_SELECT_OPTIONS
} from '@root/constants'

const DEFAULT_USER_DETAILS_TO_SHOW = ['gsuite_orgUnitPath', 'azure_department']

const uiReducerExpander = (state: UiState, action: UiAction): UiState => {
  switch (action.type) {
    case 'TOGGLE_DELETE_IMPORTED_USERS_POPUP': {
      const { isOpen, idApp, importMode, idAppAccount, appAccountName, workflowsToInvalidate, lastSyncTime } = action.payload
      return {
        ...state,
        deleteImportedUsersPopup: {
          isOpen,
          idApp,
          importMode,
          idAppAccount,
          appAccountName,
          workflowsToInvalidate,
          lastSyncTime
        }
      }
    }

    case 'TOGGLE_COPY_APP_DATA_POPUP': {
      const { isOpen, idApp, appName, possibleDuplicateApp } = action.payload
      return {
        ...state,
        copyAppDataPopup: {
          isOpen,
          idApp,
          appName,
          possibleDuplicateApp
        }
      }
    }

    case `GET_DASHBOARDS_RESPONSE`: {
      const { dashboards } = action.payload

      const tables = {}
      for (const dashboard of dashboards) {
        const { widgets } = dashboard
        const allWidgets = [...widgets, ...initialDashboardsBySystemKey.productTest.widgets] as Widget[]

        allWidgets.reduce((acc, widget) => {
          const table = getWidgetTableKey(widget)
          if (widget.type !== WIDGET_TYPES.FIXED_WIDGET && widget.drillDownConfig) {
            const columnsConfiguration = widget.drillDownConfig.fields
            const filters = widget.dataConfig.filters
            const defaultSort = createSortObjectArrayFromSortStringArray(widget.drillDownConfig.sort)
            acc[table] = { columnsConfiguration, filters, defaultSort, sortedOnce: true }
          }

          if (widget.type === WIDGET_TYPES.TABLE_WIDGET) {
            const widgetTable = getTableWidgetTableKey(widget)
            const widgetColumns = widget.displayConfig.fields
            const widgetFilters = widget.dataConfig.filters
            const widgetDefaultSort = createSortObjectArrayFromSortStringArray(widget.displayConfig.sort)
            acc[widgetTable] = { columnsConfiguration: widgetColumns, filters: widgetFilters, defaultSort: widgetDefaultSort, sortedOnce: true }
          }

          return acc
        }, tables)
      }

      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          ...tables
        }
      }
    }

    default: {
      return state
    }
  }
}

export const tsInitialState: UiState = {
  deleteImportedUsersPopup: { isOpen: false } as DeleteImportedUsersPopupState,
  userPreferences: {
    [TABLES.appsV2Table.key]: {
      defaultSort: [
        { id: 'expenses', desc: true },
        { id: 'activeUsersCount', desc: true }
      ],
      columnsConfiguration: ['name', 'owner', 'activeUsersCount', 'expenses', 'score', 'state']
    },
    [TABLES.appUsersTable.key]: {
      defaultSort: [
        { id: 'score', desc: true }
      ],
      columnsConfiguration: ['fullName', 'originEmail', 'appAccountName', 'lastVisitTime', 'licenses.name', 'score', 'externalStatus', 'sources', 'role', 'annualCostConverted'].concat(DEFAULT_USER_DETAILS_TO_SHOW),
      defaultCustomSelectOption: APP_USERS_CUSTOM_SELECT_OPTIONS_FOR_NON_CONNECTED_APPS.active
    },
    [TABLES.appAccountUsersTable.key]: {
      defaultSort: [
        { id: 'fullName', desc: false }
      ],
      columnsConfiguration: ['fullName', 'originEmail', 'lastVisitTime', 'licenses.name', 'score', 'externalStatus', 'sources'].concat(DEFAULT_USER_DETAILS_TO_SHOW)
    },
    [TABLES.appAccountLicenseUsersTable.key]: {
      defaultSort: [
        { id: 'fullName', desc: false }
      ],
      columnsConfiguration: ['fullName', 'originEmail', 'lastVisitTime', 'licenses.name', 'score', 'externalStatus', 'sources'].concat(DEFAULT_USER_DETAILS_TO_SHOW)
    },
    [TABLES.appActiveUsersListPopup.key]: {
      defaultSort: [
        { id: 'score', desc: true }
      ],
      columnsConfiguration: ['fullName', 'licenses.name', 'score', 'lastVisitTime']
    },
    [TABLES.usersListPopup.key]: {
      defaultSort: [
        { id: 'name', desc: true }
      ]
    },
    [TABLES.usersOverlapsPopup.key]: {
      columnsConfiguration: Object.keys(TABLES.usersOverlapsPopup.preDefinedColumnsMapping).concat(DEFAULT_USER_DETAILS_TO_SHOW)
    },
    [TABLES.inactiveUsersListPopup.key]: {
      defaultSort: [
        { id: 'licenseName', desc: true }
      ],
      columnsConfiguration: Object.keys(TABLES.inactiveUsersListPopup.preDefinedColumnsMapping).concat(DEFAULT_USER_DETAILS_TO_SHOW)
    },
    [TABLES.teamUsersTable.key]: {
      defaultSort: []
    },
    [TABLES.expensesTable.key]: {
      defaultSort: [
        { id: 'total', desc: true }
      ]
    },
    [TABLES.allExpensesTable.key]: {
      defaultSort: [
        { id: 'transactionDate', desc: true }
      ],
      columnsConfiguration: Object.keys(TABLES.allExpensesTable.preDefinedColumnsMapping),
      defaultCustomSelectOption: EXPENSES_TABLE_CUSTOM_SELECT_OPTIONS.matched,
      defaultSecondaryCustomSelectOption: EXPENSES_TABLE_SECONDARY_CUSTOM_SELECT_OPTIONS[EXPENSES_RANGE_OPTIONS.LAST_12_MONTHS]
    },
    [TABLES.appExpensesTable.key]: {
      defaultSort: [
        { id: 'transactionDate', desc: true }
      ],
      columnsConfiguration: Object.keys(TABLES.appExpensesTable.preDefinedColumnsMapping),
      defaultCustomSelectOption: EXPENSES_TABLE_CUSTOM_SELECT_OPTIONS.matched,
      defaultSecondaryCustomSelectOption: EXPENSES_TABLE_SECONDARY_CUSTOM_SELECT_OPTIONS[EXPENSES_RANGE_OPTIONS.LAST_12_MONTHS]
    },
    [TABLES.allDiscoveredAppsTable.key]: {
      defaultSort: [
        { id: 'mappedIdApp', desc: false }
      ],
      columnsConfiguration: ['name', 'source', 'mappingLogic', 'mappedIdApp', 'creationTime', 'accountName']
    },
    [TABLES.appDiscoveredAppsTable.key]: {
      defaultSort: [
        { id: 'mappedIdApp', desc: false }
      ],
      columnsConfiguration: ['name', 'mappingLogic', 'mappedIdApp', 'creationTime', 'accountName']
    },
    [TABLES.monthlyExpensesReportTable.key]: {
      defaultSort: [
        {
          id: 'total',
          desc: true
        }
      ]
    },
    [TABLES.extensionRolloutReportTable.key]: {
      defaultSort: [
        {
          id: 'lastReportedTime',
          desc: true
        }
      ]
    },
    [TABLES.thirdPartyReportTable.key]: {
      defaultSort: [
        {
          id: 'riskLevel',
          desc: true
        }
      ]
    },
    [TABLES.inactiveUsersReportV2Table.key]: {
      defaultSort: [
        {
          id: 'lastVisitTime',
          desc: true
        }
      ],
      defaultCustomSelectOption: INACTIVE_USERS_REPORT_TABLE_CUSTOM_SELECT_OPTIONS.all
    },
    [TABLES.userAppsTable.key]: {
      defaultSort: [
        {
          id: 'score',
          desc: true
        }
      ]
    },
    [TABLES.usersTable.key]: {
      defaultSort: [
        {
          id: 'fullName',
          desc: false
        }
      ],
      columnsConfiguration: ['fullName', 'additionalEmails', 'activeAppsCount'].concat(DEFAULT_USER_DETAILS_TO_SHOW)
    },
    [TABLES.catalogUsersList.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.deletedUsersTable.key]: {
      defaultSort: [
        {
          id: 'identitySourcesDeletionTime',
          desc: true
        }
      ],
      columnsConfiguration: ['fullName', 'email', 'additionalEmails', 'identitySourcesDeletionTime']
    },
    [TABLES.externalUsersTable.key]: {
      defaultSort: [
        {
          id: 'fullName',
          desc: false
        }
      ]
    },
    [TABLES.transactionsTable.key]: {
      defaultSort: [
        {
          id: 'transactionDate',
          desc: false
        }
      ]
    },
    [TABLES.licensesTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ],
      defaultCustomSelectOption: LICENSES_CUSTOM_SELECT_OPTIONS.paid
    },
    [TABLES.ssoAuditTable.key]: {
      defaultSort: [
        {
          id: 'managedUsers',
          desc: true
        }
      ]
    },
    [TABLES.workflowsTable.key]: {
      defaultSort: [
        {
          id: 'creationTime',
          desc: false
        }
      ]
    },
    [TABLES.customAppsTable.key]: {
      defaultSort: [
        {
          id: 'creationTime',
          desc: false
        }
      ]
    },
    [TABLES.hiddenAppsTable.key]: {
      defaultSort: [
        {
          id: 'hiddenTime',
          desc: false
        }
      ]
    },
    [TABLES.notConfiguredAppsTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.configuredAppsTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.needsAttentionAppsTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.automaticOffboardingTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.delegateOffboardingTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.ignoredOffboardingTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.offboardingTodoTable.key]: {
      defaultSort: [
        {
          id: 'identitySourcesDeletionTime',
          desc: true
        }
      ]
    },
    [TABLES.offboardingInprogressTable.key]: {
      defaultSort: [
        {
          id: 'status',
          desc: true
        }
      ]
    },
    [TABLES.offboardingDoneTable.key]: {
      defaultSort: [
        {
          id: 'offboardingEndTime',
          desc: true
        }
      ]
    },
    [TABLES.chargeBackReportTableV2.key]: {
      defaultSort: [{}]
    },
    [TABLES.appsListPopupTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.appDetailHistoryPopupTable.key]: {
      defaultSort: [
        {
          id: 'creationTime',
          desc: false
        }
      ]
    },
    [TABLES.userDetailHistoryPopupTable.key]: {
      defaultSort: [
        {
          id: 'creationTime',
          desc: false
        }
      ]
    },
    [TABLES.contractDetailHistoryPopupTable.key]: {
      defaultSort: [
        {
          id: 'creationTime',
          desc: false
        }
      ]
    },
    [TABLES.userTasksTable.key]: {
      defaultSort: [
        {
          id: 'requestedOn',
          desc: false
        }
      ]
    },
    [TABLES.contractsTable.key]: {
      defaultSort: [
        { id: 'endDate', desc: true }
      ],
      columnsConfiguration: ['name', 'idApp', 'status', 'endDate', 'amount', 'owner', 'documents']
    },
    [TABLES.unmatchedContractsTable.key]: {
      defaultSort: [
        { id: 'id', desc: true }
      ],
      columnsConfiguration: ['name', 'source', 'supplier', 'documents']
    },
    [TABLES.appContractsTable.key]: {
      defaultSort: [
        { id: 'endDate', desc: true }
      ],
      columnsConfiguration: ['name', 'idApp', 'status', 'endDate', 'amount', 'owner', 'documents']
    },
    [TABLES.appChargebackTable.key]: {
      defaultSort: [
        { id: 'totalCost', desc: true }
      ]
    },
    [TABLES.catalogAppsPopup.key]: {
      defaultSort: [
        { id: 'creationTime', desc: true }
      ],
      columnsConfiguration: ['name', 'owner', 'activeUsersCount', 'creationTime', 'score', 'state', 'appearsInCatalog', 'sources']
    },
    [TABLES.workflowsActionExecutionsTable.key]: {},
    [TABLES.apiKeysTable.key]: {},
    [TABLES.licenseCostTable.key]: {},
    [TABLES.detailsGroupTable.key]: {},
    [TABLES.expensesUploadActivityTable.key]: {},
    [TABLES.triggerPreviewPopup.key]: {},
    [TABLES.mapUsersTable.key]: {},
    [TABLES.uploadsTable.key]: {},
    [TABLES.workflowsExecutionsTable.key]: {},
    [TABLES.comparisonRecommendationsTable.key]: {
      defaultSort: [
        {
          id: 'overlappingUsers',
          desc: true
        },
        {
          id: 'similarApps',
          desc: false
        }
      ]
    },
    [TABLES.integrationsTable.key]: {
      defaultSort: [
        { id: 'name', desc: false }
      ],
      defaultCustomSelectOption: INTEGRATIONS_TABLE_CUSTOM_SELECT_OPTIONS.all
    },
    [TABLES.licenseRecommendationsTable.key]: {
      defaultSort: [],
      columnsConfiguration: Object.keys(TABLES.licenseRecommendationsTable.preDefinedColumnsMapping).concat(['gsuite_department', 'okta_department', 'azure_department'])
    },
    renewalsSummary: {
      baseYear: { value: 0, label: '' }
    },
    [TABLES.monthlyExpensesReport.key]: {
      defaultSort: [
        { id: 'total', desc: true }
      ],
      columnsConfiguration: ['name', 'owner', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', 'total']
    },
    [TABLES.workflowsExecutionsAudit.key]: {
      defaultSort: [{ id: 'id', desc: true }]
    },
    [TABLES.scheduledReportsTable.key]: {
      defaultSort: [{ id: 'reportName', desc: true }],
      columnsConfiguration: ['reportName', 'scheduleDetails.scheduleFrequency', 'status', 'nextScheduledTime', 'createdBy', 'isEnabled']
    },
    [TABLES.contractLineItemsTable.key]: {
      columnsConfiguration: ['description', 'quantity', 'pricePerUnit', 'totalCost']
    },
    [TABLES.openTasksTable.key]: {},
    [TABLES.completedTasksTable.key]: {},
    [TABLES.canceledTasksTable.key]: {},
    theme: {
      value: 'light'
    },
    lastViewedDashboard: {
      id: null
    }
  },
  copyAppDataPopup: {
    isOpen: false,
    idApp: null,
    appName: null,
    possibleDuplicateApp: null
  }
}

export default uiReducerExpander
