import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@toriihq/design-system'
import findLastIndex from 'lodash/findLastIndex'
import pluralize from 'pluralize'
import { getFormattedDate } from '@lenses/utils'
import * as Style from './style'

const findStep = (steps = [], score = 0) => {
  return findLastIndex(steps, stepValue => (score > stepValue)) + 1
}

const ScoreBars = ({ score, steps, legend, width, initialHeight, diffHeight, margin, tooltipText, lastVisitTime, showLastVisit }) => {
  let tooltipHtml
  if (legend) {
    tooltipHtml = legend[findStep(steps, score)]
  } else {
    const daysInUse = score ? `Used ${score} ${pluralize('day', score)} ${tooltipText}` : 'No usage'
    const daysInUseTooltipText = `${daysInUse} during the last 30 days`
    const lastUsedTimeTooltipText = lastVisitTime && showLastVisit ? <span>Last used: <Style.DateText>{getFormattedDate({ date: lastVisitTime })}</Style.DateText></span> : ''
    tooltipHtml = <Style.TooltipContent>{daysInUseTooltipText}<br />{lastUsedTimeTooltipText}</Style.TooltipContent>
  }

  return <Tooltip
    label={tooltipHtml}
  >
    <Style.BarContainer>
      {steps.map((stepValue, i) => (
        <Style.Bar
          key={i}
          isActive={score > stepValue}
          width={width}
          height={initialHeight + i * diffHeight}
          margin={margin}
        />
      ))}
    </Style.BarContainer>
  </Tooltip>
}

ScoreBars.propTypes = {
  score: PropTypes.number,
  steps: PropTypes.array,
  width: PropTypes.number,
  initialHeight: PropTypes.number,
  diffHeight: PropTypes.number,
  margin: PropTypes.number,
  legend: PropTypes.array,
  tooltipText: PropTypes.string,
  showLastVisit: PropTypes.bool
}

ScoreBars.defaultProps = {
  steps: [0, 4, 9],
  width: 4,
  initialHeight: 6,
  diffHeight: 2,
  margin: 2,
  tooltipText: '',
  showLastVisit: true
}

export default ScoreBars
