import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@toriihq/design-system'
import CoreApp from '../coreApp'
import ScoreBars from '../scoreBars'
import { scoreSpecialValues } from '../../constants'
import NotCollectingIcon from './notCollectingIcon'

const UsageIcon = (props) => {
  const { score, isCore, isUserUsage } = props
  if (isCore) {
    return <CoreApp />
  }

  if (score === scoreSpecialValues.onlyExtensionLowUsage) {
    return (
      <Tooltip placement='top' label='Torii does not have enough data to calculate average visits frequency'>
        <NotCollectingIcon />
      </Tooltip>
    )
  }

  if (score === scoreSpecialValues.notCollectingUsage) {
    return (
      <Tooltip placement='top' label={`Usage is not provided by data sources ${isUserUsage ? ' for this user' : ''}`}>
        <NotCollectingIcon />
      </Tooltip>
    )
  }

  return <ScoreBars {...props} />
}

UsageIcon.propTypes = {
  score: PropTypes.number,
  isCore: PropTypes.bool,
  width: PropTypes.number,
  initialHeight: PropTypes.number,
  diffHeight: PropTypes.number,
  margin: PropTypes.number,
  legend: PropTypes.array,
  tooltipText: PropTypes.string,
  isUserUsage: PropTypes.bool,
  lastVisitTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

UsageIcon.defaultProps = {
  isUserUsage: false,
  isCore: false,
  score: 0,
  width: 4,
  initialHeight: 6,
  diffHeight: 2,
  margin: 2
}

export default UsageIcon
