import { createSelector } from 'reselect'
import { State } from '@reducers/types'
import { CanceledTasksState, CompletedTasksState, OpenTasksState, TasksFiltersData } from '@reducers/tasks/types'
import identity from 'lodash/identity'
import { FilterOption } from '@root/constants.t'
import { FilterFieldValuesByKey } from '@root/shared/filters/types'

export const getOpenTasks = createSelector<State, OpenTasksState, OpenTasksState>(
  (state) => state.tasks.openTasks,
  identity
)

export const getOpenTasksAmount = createSelector<State, OpenTasksState, number>(
  getOpenTasks,
  (state) => state.totalUnfiltered
)

export const getCompletedTasks = createSelector<State, CompletedTasksState, CompletedTasksState>(
  (state) => state.tasks.completedTasks,
  identity
)

export const getCanceledTasks = createSelector<State, CanceledTasksState, CanceledTasksState>(
  (state) => state.tasks.canceledTasks,
  identity
)

export const getOpenTasksFiltersData = createSelector<State, OpenTasksState, TasksFiltersData>(
  [getOpenTasks],
  ({ filters }) => filters
)

export const getOpenTasksFilterOptions = createSelector<State, TasksFiltersData, FilterOption[]>(
  [getOpenTasksFiltersData],
  ({ filterOptions }) => filterOptions
)

export const getOpenTasksFieldValues = createSelector<State, TasksFiltersData, FilterFieldValuesByKey>(
  [getOpenTasksFiltersData],
  ({ fieldValues }) => fieldValues
)

export const getCompletedTasksFiltersData = createSelector<State, CompletedTasksState, TasksFiltersData>(
  [getCompletedTasks],
  ({ filters }) => filters
)

export const getCompletedTasksFilterOptions = createSelector<State, TasksFiltersData, FilterOption[]>(
  [getCompletedTasksFiltersData],
  ({ filterOptions }) => filterOptions
)

export const getCompletedTasksFieldValues = createSelector<State, TasksFiltersData, FilterFieldValuesByKey>(
  [getCompletedTasksFiltersData],
  ({ fieldValues }) => fieldValues
)

export const getCanceledTasksFiltersData = createSelector<State, CanceledTasksState, TasksFiltersData>(
  [getCanceledTasks],
  ({ filters }) => filters
)

export const getCanceledTasksFilterOptions = createSelector<State, TasksFiltersData, FilterOption[]>(
  [getCanceledTasksFiltersData],
  ({ filterOptions }) => filterOptions
)

export const getCanceledTasksFieldValues = createSelector<State, TasksFiltersData, FilterFieldValuesByKey>(
  [getCanceledTasksFiltersData],
  ({ fieldValues }) => fieldValues
)
